export default interface WorkOrderControlState {
  control_id: string;
  scheduling_group_items: Array<SchedulingGroup>;
  prevent_excess_completions: string;
  end_Job_Stock_Transfer_Mode: string;
  oper_time_incr: string;
  scheduling_tab_layout: string;
  gantt_status_items: Array<GanttColorCode>;
  substatus_status_items: Array<WOSubStatusItem>;
}

export interface SchedulingGroup {
  scheduling_group: string;
  scheduling_group_description: string;
  scheduling_workcenter_items: Array<WorkCenter>;
}

export interface WorkCenter {
  scheduling_workcenter: string;
}

export enum EndJobStockTransferModes {
  NONE,
  OPTIONAL_NEXT_OPERATION,
  OPTIONAL_OPERATION,
}

export interface GanttColorCode {
  gantt_hex: string;
  gantt_status: string;
}
export interface WOSubStatusItem {
  wo_substatus_status: string;
  wo_substatus_items?: Array<{ wo_substatus?: string }>;
}
export interface WOSubStatus {
  wo_substatus_status_items: Array<WOSubStatusItem>;
}
